import i18next from "i18next";
import { initReactI18next } from "react-i18next";
// "Inline" English and Arabic translations.
// We can localize to any language and any number of languages.
const resources = {
  en: {
    translation: {
      SubTitleOne: "We Bring Hope",
      DownloadFor: "Download for",
      SubTitleTwo: "to Millions of People",
    },
  },
  es: {
    translation: {
      SubTitleOne: "Depositamos Esperanza",
      DownloadFor: "Descargar para",
      SubTitleTwo: "a Millones de Personas",
    },
  },
};
i18next.use(initReactI18next).init({
  resources,
  lng: "es",
  interpolation: {
    escapeValue: false,
  },
});
export default i18next;
