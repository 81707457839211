import "./App.scss";
import AppIcon from "./assets/bamx.png";
import { useTranslation } from "react-i18next";
import React from "react";
import { faApple, faAndroid } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function App() {
  const [t, i18n] = useTranslation();
  // Current language
  const currentLanguage = i18n.language;
  return (
    <React.Fragment>
      <div className="language-selector">
        <button
          className={`language-selector__button ${
            currentLanguage === "en" ? "language-selector__button--active" : ""
          }`}
          onClick={() => i18n.changeLanguage("en")}
        >
          English
        </button>
        <button
          className={`language-selector__button ${
            currentLanguage === "es" ? "language-selector__button--active" : ""
          }`}
          onClick={() => i18n.changeLanguage("es")}
        >
          Español
        </button>
      </div>

      <div className="download">
        <img className="app-icon" src={AppIcon} alt="App Logo"></img>

        <h1 className="download__title">BAMX App</h1>
        <h2 className="download__subtitle">{t("SubTitleOne")}</h2>
        <h2 className="download__subtitle">{t("SubTitleTwo")}</h2>

        <div className="download__row">
          <a
            href="https://appdistribution.firebase.dev/i/e5a4b0e9dbadb266"
            className="icon-link icon-link--apple"
          >
            <FontAwesomeIcon icon={faApple} className="icon" />

            <span className="icon-link__text">
              <span className="icon-link__eyebrow">{t("DownloadFor")}</span>
              <span className="icon-link__source">iOS</span>
            </span>
          </a>

          <a
            href="https://appdistribution.firebase.dev/i/5db5d832c03f1f58"
            className="icon-link icon-link--google"
          >
            <FontAwesomeIcon icon={faAndroid} className="icon" />

            <span className="icon-link__text">
              <span className="icon-link__eyebrow">{t("DownloadFor")}</span>
              <span className="icon-link__source">Android</span>
            </span>
          </a>
        </div>
      </div>

      {/* Add demo title to video */}
      <h2 className="demo-title">Demo</h2>

      <div className="video">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/pzzGUk5iOeY"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </div>
    </React.Fragment>
  );
}

export default App;
